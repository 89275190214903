<template>
  <div class="row">
    <district-form :submit-text="'Submit Changes'" :id-district="idDistrict" :editing-existing="true"
                   :header-text="'Edit a Nebraska District'"/>
  </div>
</template>

<script>
import DistrictForm from "@/views/SuperUserViews/EducationalOrganizationManagement/District/DistrictForm";

export default {
  name: "DistrictEdit",
  components: {DistrictForm},
  props: {
    idDistrict: Number,
  },
  setup(props, context) {

  }
}
</script>

<style scoped>

</style>